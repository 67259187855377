import { REQUIRED_FIELD, ONLY_NUMBER, INVALID_FIELD } from '@/utils/validationMessagesUtils'

export default {
  institutionParentIdErrors () {
    const errors = []
    if (!this.$v.item.parentId.$dirty) return errors
    !this.$v.item.parentId.required && errors.push(REQUIRED_FIELD)
    return errors
  },

  institutionCodErrors () {
    const errors = []
    if (!this.$v.item.cod.$dirty) return errors
    !this.$v.item.cod.required && errors.push(REQUIRED_FIELD)
    !this.$v.item.cod.isOnlyNumber && errors.push(ONLY_NUMBER)
    return errors
  },

  institutionCnpjErrors () {
    const errors = []
    if (!this.$v.item.cnpj.$dirty) return errors
    !this.$v.item.cnpj.required && errors.push(REQUIRED_FIELD)
    !this.$v.item.cnpj.isValidCnpj && errors.push(INVALID_FIELD)
    return errors
  },

  institutionNameErrors () {
    const errors = []
    if (!this.$v.item.name.$dirty) return errors
    !this.$v.item.name.required && errors.push(REQUIRED_FIELD)
    return errors
  },

  institutionEmailErrors () {
    const errors = []
    if (!this.$v.item.email.$dirty) return errors
    !this.$v.item.email.required && errors.push(REQUIRED_FIELD)
    !this.$v.item.email.email && errors.push('Por favor, digitar um email válido.')
    return errors
  },

  institutionAddressErrors () {
    const errors = []
    if (!this.$v.item.address.$dirty) return errors
    !this.$v.item.address.required && errors.push(REQUIRED_FIELD)
    return errors
  },

  institutionPhoneErrors () {
    const errors = []
    if (!this.$v.item.phone.$dirty) return errors
    !this.$v.item.phone.required && errors.push(REQUIRED_FIELD)
    !this.$v.item.phone.minLength && errors.push(INVALID_FIELD)
    return errors
  },

  institutionCepErrors () {
    const errors = []
    if (!this.$v.item.cep.$dirty) return errors
    !this.$v.item.cep.required && errors.push(REQUIRED_FIELD)
    !this.$v.item.cep.minLength && errors.push('Por favor, digitar um CEP válido.')
    return errors
  },

  institutionCityErrors () {
    const errors = []
    if (!this.$v.item.city.$dirty) return errors
    !this.$v.item.city.required && errors.push(REQUIRED_FIELD)
    return errors
  },

  institutionStateErrors () {
    const errors = []
    if (!this.$v.item.state.$dirty) return errors
    !this.$v.item.state.required && errors.push(REQUIRED_FIELD)
    return errors
  },

  institutionNeighborhoodErrors () {
    const errors = []
    if (!this.$v.item.neighborhood.$dirty) return errors
    !this.$v.item.neighborhood.required && errors.push(REQUIRED_FIELD)
    return errors
  },

  institutionNumberErrors () {
    const errors = []
    if (!this.$v.item.number.$dirty) return errors
    !this.$v.item.number.required && errors.push(REQUIRED_FIELD)
    return errors
  },

  responsibleNameErrors () {
    const errors = []
    if (!this.$v.item.responsible.name.$dirty) return errors
    !this.$v.item.responsible.name.required && errors.push(REQUIRED_FIELD)
    return errors
  },

  responsibleEmailErrors () {
    const errors = []
    if (!this.$v.item.responsible.email.$dirty) return errors
    !this.$v.item.responsible.email.required && errors.push(REQUIRED_FIELD)
    !this.$v.item.responsible.email.email && errors.push('Por favor, digitar um email válido.')
    return errors
  },

  responsibleCpfErrors () {
    const errors = []
    if (!this.$v.item.responsible.cpf.$dirty) return errors
    !this.$v.item.responsible.cpf.required && errors.push(REQUIRED_FIELD)
    !this.$v.item.responsible.cpf.isValidCpf && errors.push(INVALID_FIELD)
    return errors
  },

  responsibleAddressErrors () {
    const errors = []
    if (!this.$v.item.responsible.address.$dirty) return errors
    !this.$v.item.responsible.address.required && errors.push(REQUIRED_FIELD)
    return errors
  },

  responsiblePhoneErrors () {
    const errors = []
    if (!this.$v.item.responsible.phone.$dirty) return errors
    !this.$v.item.responsible.phone.required && errors.push(REQUIRED_FIELD)
    !this.$v.item.responsible.phone.minLength && errors.push(INVALID_FIELD)
    return errors
  },

  responsibleBusinessPhoneErrors () {
    const errors = []
    if (!this.$v.item.responsible.businessPhone.$dirty) return errors
    !this.$v.item.responsible.businessPhone.required && errors.push(REQUIRED_FIELD)
    !this.$v.item.responsible.businessPhone.minLength && errors.push(INVALID_FIELD)
    return errors
  },

  responsibleCepErrors () {
    const errors = []
    if (!this.$v.item.responsible.cep.$dirty) return errors
    !this.$v.item.responsible.cep.required && errors.push(REQUIRED_FIELD)
    !this.$v.item.responsible.cep.minLength && errors.push('Por favor, digitar um CEP válido.')
    return errors
  },

  responsibleCityErrors () {
    const errors = []
    if (!this.$v.item.responsible.city.$dirty) return errors
    !this.$v.item.responsible.city.required && errors.push(REQUIRED_FIELD)
    return errors
  },

  responsibleStateErrors () {
    const errors = []
    if (!this.$v.item.responsible.state.$dirty) return errors
    !this.$v.item.responsible.state.required && errors.push(REQUIRED_FIELD)
    return errors
  },

  responsibleNeighborhoodErrors () {
    const errors = []
    if (!this.$v.item.responsible.neighborhood.$dirty) return errors
    !this.$v.item.responsible.neighborhood.required && errors.push(REQUIRED_FIELD)
    return errors
  },

  responsibleNumberErrors () {
    const errors = []
    if (!this.$v.item.responsible.number.$dirty) return errors
    !this.$v.item.responsible.number.required && errors.push(REQUIRED_FIELD)
    return errors
  },

  institutionRegionErrors () {
    const errors = []
    // if (!this.$v.item.region.$dirty) return errors
    // !this.$v.item.region.required && errors.push(REQUIRED_FIELD)
    return errors
  },

  responsibleRgErrors () {
    const errors = []
    if (!this.$v.item.responsible.rg.$dirty) return errors
    !this.$v.item.responsible.rg.required && errors.push(REQUIRED_FIELD)
    return errors
  },

  responsibleIssuingBodyErrors () {
    const errors = []
    if (!this.$v.item.responsible.rg.$dirty) return errors
    !this.$v.item.responsible.rg.required && errors.push(REQUIRED_FIELD)
    return errors
  },
}