<template>
  <Modal
    :activeModal="activeModal"
    @closeModal="closeModal">
    <template v-slot:btnActivator>
      <v-btn
        tile
        :color="variables.colorPrimary"
        dark
        class="mb-2 modal-cadastro-btn-cadastrar"
        @click="openModal"
        v-if="permiteAcao($route, 'add')"
        :loading="loadingBtnCadastro">Novo cadastro</v-btn>
    </template>
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
        {{ isEdit ? 'Editar Supervisor' : 'Cadastrar Supervisor' }}
        <v-spacer></v-spacer>
        <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <div class="abas" v-show="isEdit" width="100%" v-if="false">
        <v-tabs
          v-model="tab"
          background-color="#FFF"
          height="30px">
          <v-tab href="#tab-1" class="abas-item">
            Supervisor
          </v-tab>

          <!-- <v-tab href="#tab-2" class="abas-item" @click="requestComissao(item)">
            Contratos
          </v-tab> -->
        </v-tabs>
      </div>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item
          :key="1"
          :value="'tab-1'">
          <v-form>
            <!-- <v-card-title class="modal-cadastro-titulo">

            </v-card-title> -->

            <v-card-text class="modal-cadastro-form">
              <v-card-title>
                Dados do Supervisor
              </v-card-title>
              <v-row>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
                  <v-autocomplete
                    v-model="item.parentId"
                    :items="listaItens"
                    :label="getEntityType(1)"
                    @input="$v.item.parentId.$touch()"
                    @blur="$v.item.parentId.$touch()"
                    v-on:keyup="searchAutoComplete"
                    :search-input.sync="searchText"
                    :error-messages="institutionParentIdErrors"
                    :loading="isLoadingAutoComplete" />
                </v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="institutionCodErrors" v-model="item.cod" label="COD:" type="number" @input="$v.item.cod.$touch()" @blur="$v.item.cod.$touch()" /></v-col>
                <!-- <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="institutionRegionErrors" v-model="item.region" label="Região:" readonly filled @input="$v.item.region.$touch()" @blur="$v.item.region.$touch()" /></v-col> -->
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="institutionNameErrors" v-model="item.name" label="Nome:" @input="$v.item.name.$touch()" @blur="$v.item.name.$touch()" /></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="institutionEmailErrors" v-model="item.email" label="Email:" @input="$v.item.email.$touch()" @blur="$v.item.email.$touch()" /></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="institutionCnpjErrors" v-model="item.cnpj" label="CNPJ:" @input="$v.item.cnpj.$touch()" @blur="$v.item.cnpj.$touch()" v-mask="['##.###.###/####-##']"/></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="institutionPhoneErrors" v-model="item.phone" label="Telefone Comercial:" @input="$v.item.phone.$touch()" @blur="$v.item.phone.$touch()" v-mask="['(##) ####-####', '(##) #####-####']"/></v-col>
              </v-row>
            </v-card-text>

            <v-card-text class="modal-cadastro-form">
              <v-card-title>
                Endereço do Supervisor
              </v-card-title>
              <v-row>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="institutionCepErrors" v-model="item.cep" @keyup="buscarCep" label="CEP:" @input="$v.item.cep.$touch()" @blur="$v.item.cep.$touch()" v-mask="['#####-###']"/></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="institutionAddressErrors" v-model="item.address" label="Endereço:" @input="$v.item.address.$touch()" @blur="$v.item.address.$touch()" :disabled="cepValidate" /></v-col>
                <searchUfCity
                  @addUf="item.state = arguments[0]"
                  @addCity="item.city = arguments[0]"
                  :propUf="item.state"
                  :propCity="item.city"
                  :errorMessagesCity="institutionCityErrors"
                  :errorMessagesState="institutionStateErrors"
                  :propDisabled="cepValidate" />

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="institutionNeighborhoodErrors" v-model="item.neighborhood" label="Bairro:" @input="$v.item.neighborhood.$touch()" @blur="$v.item.neighborhood.$touch()" :disabled="cepValidate" /></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="institutionNumberErrors" v-model="item.number" label="Número:" @input="$v.item.number.$touch()" @blur="$v.item.number.$touch()" /></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="12"><v-text-field v-model="item.complement" label="Complemento:" /></v-col>
              </v-row>
            </v-card-text>

            <v-card-text class="modal-cadastro-form">
              <v-card-title>
                Dados do responsável do Supervisor:
              </v-card-title>
              <v-row>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsibleNameErrors" v-model="item.responsible.name" label="Representante:" @input="$v.item.responsible.name.$touch()" @blur="$v.item.responsible.name.$touch()" /></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsibleCpfErrors" v-model="item.responsible.cpf" label="CPF:" @input="$v.item.responsible.cpf.$touch()" @blur="$v.item.responsible.cpf.$touch()" v-mask="['###.###.###-##']"/></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsibleRgErrors" v-model="item.responsible.rg" label="RG:" @input="$v.item.responsible.rg.$touch()" @blur="$v.item.responsible.rg.$touch()" v-mask="['#######']"/></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsibleIssuingBodyErrors" v-model="item.responsible.issuingBody" label="Órgão Expeditor:" @input="$v.item.responsible.issuingBody.$touch()" @blur="$v.item.responsible.issuingBody.$touch()"/></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsiblePhoneErrors" v-model="item.responsible.phone" label="Telefone:" @input="$v.item.responsible.phone.$touch()" @blur="$v.item.responsible.phone.$touch()" v-mask="['(##) ####-####', '(##) #####-####']"/></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsibleBusinessPhoneErrors" v-model="item.responsible.businessPhone" label="Telefone Comercial:" @input="$v.item.responsible.businessPhone.$touch()" @blur="$v.item.responsible.businessPhone.$touch()" v-mask="['(##) ####-####', '(##) #####-####']"/></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsibleEmailErrors" v-model="item.responsible.email" label="Email do Responsável:" @input="$v.item.responsible.email.$touch()" @blur="$v.item.responsible.email.$touch()" /></v-col>
              </v-row>
            </v-card-text>

            <v-card-text class="modal-cadastro-form">
              <v-card-title>
              Endereço do responsável do Supervisor:
              </v-card-title>
              <v-row>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsibleCepErrors" v-model="item.responsible.cep"  @keyup="buscarCepResponsible" label="CEP:" @input="$v.item.responsible.cep.$touch()" @blur="$v.item.responsible.cep.$touch()" v-mask="['#####-###']"/></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsibleAddressErrors" v-model="item.responsible.address" label="Endereço:" @input="$v.item.responsible.address.$touch()" @blur="$v.item.responsible.address.$touch()" :disabled="cepValidateResponsible" /></v-col>
                <searchUfCity
                  @addUf="item.responsible.state = arguments[0]"
                  @addCity="item.responsible.city = arguments[0]"
                  :propUf="item.responsible.state"
                  :propCity="item.responsible.city"
                  :errorMessagesCity="responsibleCityErrors"
                  :errorMessagesState="responsibleStateErrors"
                  :propDisabled="cepValidateResponsible" />

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsibleNeighborhoodErrors" v-model="item.responsible.neighborhood" label="Bairro:" @input="$v.item.responsible.neighborhood.$touch()" @blur="$v.item.responsible.neighborhood.$touch()" :disabled="cepValidateResponsible" /></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsibleNumberErrors" v-model="item.responsible.number" label="Número:" @input="$v.item.responsible.number.$touch()" @blur="$v.item.responsible.number.$touch()" /></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="12"><v-text-field v-model="item.responsible.complement" label="Complemento:" /></v-col>
              </v-row>
            </v-card-text>

            <v-card-actions class="modal-cadastro-footer">
              <v-btn :color="variables.colorPrimary" @click="closeModal" class="br-btn br-btn-cancelar">Cancelar</v-btn>
              <v-btn
                type="submit"
                :color="variables.colorPrimary"
                @click.prevent="submit"
                class="br-btn"
                :loading="loading"
                v-if="permiteAcao($route, 'edit') || permiteAcao($route, 'add')">{{ isEdit ? 'Salvar' : 'Cadastrar' }}</v-btn>
            </v-card-actions>
          </v-form>
        </v-tab-item>

        <!-- <v-tab-item :key="2" :value="'tab-2'">
          <comission :entity="entity" v-on:close-modal="closeModal" />
        </v-tab-item> -->
      </v-tabs-items>
    </v-card>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'
import { isOnlyNumber, isValidCnpj, isValidCpf } from '@/utils/validationUtils'
import { mask } from 'vue-the-mask'
import Events from '@/core/service/events'
import axios from 'axios'
import config from '@/core/config'
import { formatPhone } from '@/core/service/utils'
import validacao from './validacao'
import searchUfCity from '@/views/components/searchUfCity'
import modalCadastro from '@/views/cadastros/entities/mixins/modalCadastro'

export default {
  name: 'BrModalCadastroOSC',
  mixins: [validationMixin, modalCadastro],
  components: {
    // comission: () => import('./comission'),
    Modal: () => import('@/views/components/modal'),
    searchUfCity
  },
  directives: {mask},
  data: () => ({
    activeModal: false,
    loading: false,
    tab: 'tab-1',
    entity: null,
    cepValidate: false,
    cepValidateResponsible: false
  }),
  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('osc', { item: 'item', listaItens: 'listaItensSelect' }),
    ...validacao
  },
  methods: {
    ...mapActions('osc', ['cadastrarItem', 'limparItem', 'getItens', 'editarItem', 'obterItensSelect']),
    ...mapActions('comissionWeb', {
      limparItemComission: 'limparItem'
    }),
    ...mapActions('comissionOscPrizes', {
      limparItemComissionOscPrizes: 'limparItem'
    }),
    closeModal () {
      this.activeModal = false
      this.tab = 'tab-1'
      this.entity = null
      this.limparItem()
      this.limparItemComission()
      this.limparItemComissionOscPrizes()
      this.$v.item.$reset()
      this.cepValidate = false,
      this.cepValidateResponsible = false
    },

    requestComissao (osc) {
      this.entity = osc
    },

    async buscarCep () {
      if(/^[0-9]{5}-[0-9]{3}$/.test(this.item.cep)){
        const result = await axios.get(config.apiViaCep + this.item.cep + "/json/")
        if (result.data.erro) {
          this.cepValidate = false
          return
        }

        this.cepValidate = true
        this.item.address = result.data.logradouro
        this.item.state = result.data.uf
        this.item.city = result.data.localidade
        this.item.neighborhood = result.data.bairro
      }
    },

    async buscarCepResponsible () {
      if(/^[0-9]{5}-[0-9]{3}$/.test(this.item.responsible.cep)){
        const result = await axios.get(config.apiViaCep + this.item.responsible.cep + "/json/")
        if (result.data.erro) {
          this.cepValidateResponsible = false
          return
        }

        this.cepValidateResponsible = true
        this.item.responsible.address = result.data.logradouro
        this.item.responsible.state = result.data.uf
        this.item.responsible.city = result.data.localidade
        this.item.responsible.neighborhood = result.data.bairro
      }
    },

    submit () {
      if (this.$v.item.$invalid) {
        this.$v.item.$touch()
        return false
      }

      this.loading = true

      const dados = {
        parentId: this.item.parentId || null,
        jsonData: {
          cod: this.item.cod,
          region: this.item.region || 'GYN',
          name: this.item.name,
          email: this.item.email.toLowerCase(),
          cnpj: this.item.cnpj.replace(/\D/g, ''),
          address: this.item.address,
          cep: this.item.cep.replace(/\D/g, ''),
          phone: formatPhone(this.item.phone),
          city: this.item.city,
          state: this.item.state,
          neighborhood: this.item.neighborhood,
          number: this.item.number,
          complement: this.item.complement,
          responsible: {
            name: this.item.responsible.name,
            email: this.item.responsible.email.toLowerCase(),
            cpf: this.item.responsible.cpf.replace(/\D/g, ''),
            rg: this.item.responsible.rg.replace(/\D/g, ''),
            issuingBody: this.item.responsible.issuingBody,
            phone: formatPhone(this.item.responsible.phone),
            businessPhone: formatPhone(this.item.responsible.businessPhone),
            cep: this.item.responsible.cep.replace(/\D/g, ''),
            address: this.item.responsible.address,
            state: this.item.responsible.state,
            city: this.item.responsible.city,
            neighborhood: this.item.responsible.neighborhood,
            number: this.item.responsible.number,
            complement: this.item.responsible.complement
          }
        }
      }

      if (this.item.id) {
        dados.id = this.item.id

        this.editarItem(dados).then(() => {
          this.getItens({ page: 1, pageSize: this.$store.getters.ItensPaginacao })
          this.closeModal()
          this.loading = false
          const msg = {
            toggle: true,
            type: 'success',
            msg: 'Edição realizado com sucesso!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        }).catch(err => {
          this.loading = false
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.msg
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
      } else {
        this.cadastrarItem(dados).then(result => {
          this.getItens({ page: 1, pageSize: this.$store.getters.ItensPaginacao })
          this.loading = false
          this.entity = {
            id: result.data.id,
            name: result.data.jsonData.name
          }
            this.closeModal()
          // this.tab = 'tab-2'
          const msg = {
            toggle: true,
            type: 'success',
            msg: 'Cadastro realizado com sucesso!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        }).catch(err => {
          this.loading = false
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.error
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
      }

    },
  },

  validations () {
    return  {
      item: {
        parentId: { required },
        cod: { required, isOnlyNumber},
        // region: { required },
        name: { required },
        email: { required, email },
        cnpj: { required, isValidCnpj },
        address: { required },
        cep: { required, minLength: minLength(9) },
        phone: { required, minLength: minLength(14), maxLength: maxLength(15) },
        city: { required },
        state: { required },
        neighborhood: { required },
        number: { required },
        responsible: {
          name: { required },
          email: { required, email },
          cpf: { required, isValidCpf },
          rg: { required },
          issuingBody: { required },
          address: { required },
          cep: { required, minLength: minLength(9) },
          phone: { required, minLength: minLength(14), maxLength: maxLength(15) },
          businessPhone: { required, minLength: minLength(14), maxLength: maxLength(15) },
          city: { required },
          state: { required },
          neighborhood: { required },
          number: { required },
        }
      }
    }
  }
}
</script>

<style lang="scss">
  @import '../../../../../assets/styles/components/formularios';
  @import '../../../../../assets/styles/components/modal-cadastro';
</style>